import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import SignInPage from './pages/SignInPage';
import MainNavbar from './components/MainNavbar';
import AddRealEstate from './pages/AddRealEstate';
import EditRealEstate from './pages/EditRealEstate';
import RealEstateList from './pages/RealEstateList';
import RealEstatePage from './pages/RealEstatePage';
import RealEstateDetailPage from './pages/RealEstateDetailPage';
import ProtectedRoute from './utils/ProtectedRoute';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route element={ <MainNavbar /> } path='/'>
              <Route element={ <HomePage /> } path=''/>
              <Route element={ <AboutPage /> } path='O_nas'/>
              <Route element={ <ContactPage /> } path='Kontakt'/>
              <Route element={ <RealEstatePage /> } path='Nepremičnine'/>
              <Route element={ <RealEstateDetailPage /> } path='Nepremičnine/:id'/>
            </Route>
            <Route path='/' element={<ProtectedRoute/>}>
            <Route element={ <RealEstateList /> } path='/list'/>
            </Route>
            <Route element={ <SignInPage /> } path='/login'/>
            <Route path='/' element={<ProtectedRoute/>}>
              <Route path='/new' element={<AddRealEstate/>}/>
            </Route>
            <Route path='/' element={<ProtectedRoute/>}>
            <Route element={ <EditRealEstate/> } path='/edit'/>
            </Route>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
