import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function Footer() {
  const isMobile =  window.innerWidth < 800;
  const theme = createTheme({
    typography: {
      footer: {
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
    },
  });
  return (
    <Container className="m-0 p-0" maxWidth={false}>
      <Box
        className="m-0"
        paddingLeft={isMobile ? "24px" : "70px"}
        paddingTop={"50px"}
        sx={{
          height: 300,
          backgroundColor: "#283B62",
        }}
      >
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Typography variant="footer">KONTAKT</Typography>
            <Box display={"flex"} >
              <LocationOnIcon sx={{ color: "white" }} />
              <Typography variant="footer" marginLeft={"20px"}>Ulica Slavka Gruma 124, 8000 Novo mesto</Typography>
            </Box>
            <Box>
              <PhoneIcon sx={{ color: "white" }} />
              <Typography variant="footer" marginLeft={"20px"}>041 310 880</Typography>
            </Box>
            <Box>
              <PhoneIcon sx={{ color: "white" }} />
              <Typography variant="footer" marginLeft={"20px"}>051 680 301</Typography>
            </Box>
            <Box>
              <MailOutlineIcon sx={{ color: "white" }} />
              <Typography variant="footer" marginLeft={"20px"}>info@sansim.si</Typography>
            </Box>
          </Stack>
        </ThemeProvider>
      </Box>
      <Box display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
        sx={{
          height: 80,
          backgroundColor: "#1E3054",
        }}
      >
        <ThemeProvider theme={theme}>
        <Typography variant="footer">SANSIM nepremičnine d.o.o.</Typography>
        </ThemeProvider>
      </Box>
    </Container>
  );
}

export default Footer;
