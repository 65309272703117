import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import backgroundImage from "../assets/hisa_1.png";
import { Image } from "mui-image";
import Footer from "../components/Footer";
import RealEstateCard from "../components/RealEstateCard";
import SearchBar from "../components/SearchBar";

import { ref, listAll, getBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { TextDecoder } from "text-encoding";

import { capitalize } from "../utils/utils";

function RealEstatePage() {
  const [displayedRealEstate, setDisplayedRealEstate] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();


  const [loading, setLoading] = useState(false);
  const [businessType, setBusinessType] = useState(new Set());
  const [types, setTypes] = useState(new Set());
  const [subtypes, setSubTypes] = useState(new Set());

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const listRef = ref(storage, "");
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          // read bytes to json and add to rows
          getBytes(itemRef)
            .then((bytes) => {
              let json = new TextDecoder().decode(bytes);
              json = JSON.parse(json);
              // Here you can get the download URL for 'images/stars.jpg'
              json.id = itemRef.name;
              setDisplayedRealEstate((oldRows) => [...oldRows, json]);
              setBusinessType(
                (oldBusinessType) =>
                  new Set([...oldBusinessType, capitalize(json.adType)])
              );
              setTypes(
                (oldTypes) => new Set([...oldTypes, capitalize(json.type)])
              );
              setSubTypes(
                (oldSubtypes) =>
                  new Set([...oldSubtypes, capitalize(json.subtype)])
              );
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log("error1: ", error);
            });
        });
      })
      .catch((error) => {
        console.log("error2: ", error);
        // Uh-oh, an error occurred!
      });
  }, []);

  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 35,
        fontStyle: "Open Sans",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: 32,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
      boxtext: {
        fontWeight: 200,
        fontSize: 20,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
    },
  });

  function onSelect(selected) {
    if (businessType.has(selected)) {
      setTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.adType === selected.toLowerCase())
            .map((e) => capitalize(e.type))
        )
      );

      setSubTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.adType === selected.toLowerCase())
            .map((e) => capitalize(e.subtype))
        )
      );
    } else if (types.has(selected)) {
      setSubTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.type === selected.toLowerCase())
            .map((e) => capitalize(e.subtype))
        )
      );
    }
  }

  function filterSearch(realEstate) {
    var businessType = true;
    var type = true;
    var subtype = true;

    if (searchParams.get("businessType") !== null) {
      businessType = realEstate.adType === searchParams.get("businessType").toLowerCase()
    }
    if (searchParams.get("type") !== null) {
      type = realEstate.type === searchParams.get("type").toLowerCase()
    }
    if (searchParams.get("subtype") !== null) {
      subtype = realEstate.subtype === searchParams.get("subtype").toLowerCase()
    }
    return businessType & type & subtype
  }

  return (
    <Box>
      <Box paddingBottom={"100px"}>
        <Image src={backgroundImage} style={{ opacity: 0.7 }} />
      </Box>
      <ThemeProvider theme={theme}>
        <Box display={"flex"} justifyContent={"center"}>
          <SearchBar
            businessType={businessType}
            types={types}
            subtypes={subtypes}
            onSelect={onSelect}
          />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          justifyContent={"center"}
          marginY={"100px"}
        >
          <Grid
            container
            spacing={4}
            maxWidth={"800px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {displayedRealEstate &&
              displayedRealEstate.filter((element) => filterSearch(element)).map((realEstate) => (
                <Grid item key={realEstate.id}>
                  <RealEstateCard
                    image={
                      realEstate.url ? realEstate.url : realEstate.mainImage
                    }
                    address={realEstate.title}
                    price={realEstate.price}
                    type={realEstate.type}
                    build_year={realEstate.date}
                    area={realEstate.area}
                    subType={realEstate.subtype}
                    adType={realEstate.adType}
                    id={realEstate.id.slice(2, -5)}
                  />
                </Grid>
              ))}
          </Grid>
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          marginY={"20px"}
        ></Stack>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginY={"20px"}
        ></Stack>
      </ThemeProvider>
      <Footer />
    </Box>
  );
}

export default RealEstatePage;
