export function renderYear(date) {
    return date.slice(0,4);
}

export function renderTitle(title) {
    return title.split("_").join(" ");
}

export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}