import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/system/Unstable_Grid";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

import SearchIcon from "@mui/icons-material/Search";

function SearchBar({ businessType, types, subtypes, onSelect }) {
  const [searchBusinessType, setSearchBusinessType] = useState("");
  const [searchTypes, setSearchTypes] = useState("");
  const [searchSubTypes, setSearchSubtypes] = useState("");
  const theme = createTheme({
    typography: {
      search: {
        fontWeight: 500,
        fontSize: 20,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
    },
  });
  const isMobile =  window.innerWidth < 800;

  function queryParams() {
    var params = [];
    if (searchBusinessType !== "") {
      params.push(`businessType=${searchBusinessType}`);
    }
    if (searchTypes !== "") {
      params.push(`type=${searchTypes}`);
    }
    if (searchSubTypes !== "") {
      params.push(`subtype=${searchSubTypes}`);
    }
    return params.join("&");
  }

  return (
    <Grid
      container
      boxShadow={2}
      width={"750px"}
      height={"55px"}
      borderRadius={"10px"}
      marginX={"16px"}
    >
      <ThemeProvider theme={theme}>
        <Grid xs={10}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Vrsta posla</InputLabel>
              <Select
                value={searchBusinessType}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Vrsta posla"
                onChange={(e) => {
                  setSearchBusinessType(e.target.value);
                  onSelect(e.target.value);
                }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
              >
                {Array.from(businessType).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Nepremičnina
              </InputLabel>
              <Select
                value={searchTypes}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tip nepremičnine"
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                onChange={(e) => {
                  setSearchTypes(e.target.value);
                  onSelect(e.target.value);
                }}
              >
                {Array.from(types).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Tip nepremičnine
              </InputLabel>
              <Select
                value={searchSubTypes}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tip nepremičnine"
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                onChange={(e) => {
                  setSearchSubtypes(e.target.value);
                  onSelect(e.target.value);
                }}
              >
                {Array.from(subtypes).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid
          xs={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"10px"}
          component={Card}
          sx={{ backgroundColor: "#283B62" }}
        >
          <CardActionArea
            component={Link}
            to={{
              pathname: "/Nepremičnine",
              search: `?${queryParams()}`,
            }}
          >
            <Box
              height={"55px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <SearchIcon sx={{ color: "white" }} />
              {!isMobile && <Typography marginLeft={"2px"} variant="search">
                Išči
              </Typography>}
            </Box>
          </CardActionArea>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}

export default SearchBar;
