import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom'
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
  }
  from 'mdb-react-ui-kit';
 
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            sessionStorage.setItem('user', JSON.stringify(user));
            navigate("/list")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
       
    }
 
    return(
<MDBContainer fluid>
<MDBRow className='d-flex justify-content-center align-items-center h-100'>
  <MDBCol col='12'>
    <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
      <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

        <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
        <p className="text-white-50 mb-5">Please enter your login and password!</p>

        <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white'
        label='Email address' id='formControlLg' type='email' size="lg"
        onChange={(e)=>setEmail(e.target.value)}
        />
        <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white'
        label='Password' id='formControlLg' type='password' size="lg"
        onChange={(e)=>setPassword(e.target.value)}
        />

        <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>
        <MDBBtn outline className='mx-2 px-5' color='white' size='lg'  onClick={onLogin}>
          Login
        </MDBBtn>
      </MDBCardBody>
    </MDBCard>

  </MDBCol>
</MDBRow>
</MDBContainer>
    )
}
 
export default Login;