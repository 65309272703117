import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Container, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { red } from "@mui/material/colors";



function EmailForm() {
  const isMobile =  window.innerWidth < 800;
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 500,
        fontSize: 28,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
    },
  });

  function onSend() {
  }

  return (
    <Box
      maxWidth={"600px"}
      width="100%"
      height={"550px"}
      borderRadius={isMobile ? "0px" : "30px"}
      sx={{
        backgroundColor: "#283B62",
        marginTop: "80px",
        marginBottom: "100px",
        paddingX: "60px",
      }}
    >
      <form action="https://formsubmit.co/info@sansim.si" method="POST">  
      <Stack display={"flex"} alignItems={"center"}>
        <ThemeProvider theme={theme}>
          <Typography variant="title" marginTop={"30px"}>
            Kontaktirajte nas
          </Typography>
        </ThemeProvider>
        <TextField
          fullWidth
          id="standard-basic"
          label="Ime in Priimek"
          name="Ime in Priimek"
          sx={{marginY: "10px" }}
          variant="standard"
          InputProps={{
            style: { color: "white" },
          }}
          InputLabelProps={{
            style: { color: "white" },
          }}
        />

        <TextField
          fullWidth
          id="standard-basic"
          label="Telefon"
          name="phone"
          sx={{marginY: "10px" }}
          variant="standard"
          InputProps={{
            style: { color: "white" },
          }}
          InputLabelProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          fullWidth
          id="standard-basic"
          label="E-mail"
          type="email"
          name="email"
          sx={{marginY: "10px" }}
          variant="standard"
          InputProps={{
            style: { color: "white" },
          }}
          InputLabelProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          fullWidth
          id="standard-basic"
          label="Sporočilo"
          name="message"
          sx={{marginY: "10px" }}
          variant="standard"
          InputProps={{
            style: { color: "white" },
          }}
          InputLabelProps={{
            style: { color: "white" },
          }}
          multiline
          rows={5}
        />
        <Button
          variant="outlined"
          type="submit"
          onClick={onSend}
          sx={{ color: "#FFFFFF", marginY: "40px", borderColor: "#FFFFFF" }}
        >
          Pošlji
        </Button>
      </Stack>
      </form>
    </Box>
  );
}

export default EmailForm;
