import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";


import { createTheme, ThemeProvider } from "@mui/material/styles";

function AboutItem({ title, text}) {
    const theme = createTheme({
        typography: {
          title: {
            fontWeight: 500,
            fontSize: 28,
            fontStyle: "Josefin Sans",
            color: "#FFFFFF",
          },
          text: {
            fontWeight: 300,
            fontSize: 18,
            fontStyle: "Josefin Sans",
            color: "#FFFFFF",
          },
        },
      });
  return (
    <Box
      maxWidth={"600px"}
      minHeight={"300px"}
      borderRadius={"50px"}
      sx={{
        backgroundColor: "#283B62",
        opacity: 0.85,
        paddingX: "60px",
        paddingY: "40px",
      }}
    >
      <Stack display={"flex"} >
        <ThemeProvider theme={theme}>
          <Typography variant="title" marginBottom={"30px"} >
            {title}
          </Typography>
          <Typography variant="text" marginBottom={"30px"} >
          {text}
          </Typography>
        </ThemeProvider>
      </Stack>
    </Box>
  )
}

export default AboutItem