
// export enums here

export const enums = {
    adType: [ {
      value: 'prodaja',
      label: 'Prodaja',
    },
    {
      value: 'oddaja',
      label: 'Oddaja',
    }],
    realEstateType: [ {
      value: 'stanovanje',
      label: 'Stanovanje',
    },
    {
      value: 'hisa',
      label: 'Hiša',
    },
    {
      value: 'parcela',
      label: 'Parcela',
    },
    {
      value: 'poslovni_prostor',
      label: 'Poslovni prostor',
    },
    {
      value: 'pocitniski_objekt',
      label: 'Počitniški objekt',
    }],
    flatRealEstateSubtype: [ {
      value: 'garsonjera',
      label: 'Garsonjera',
    },
    {
      value: '1-sobno',
      label: '1-sobno',
    },
    {
      value: '1,5-sobno',
      label: '1,5-sobno',
    },
    {
      value: '2-sobno',
      label: '2-sobno',
    },
    {
      value: '2,5-sobno',
      label: '2,5-sobno',
    },
    {
      value: '3-sobno',
      label: '3-sobno',
    },
    {
      value: '4-sobno',
      label: '4-sobno',
    },
    {
      value: '4,5-sobno',
      label: '4,5-sobno',
    },
    {
      value: '5-sobno',
      label: '5 + več sobno',
    }],
    houseRealEstateSubtype: [ {
      value: 'samostojna',
      label: 'Samostojna',
    },
    {
      value: 'vrstna',
      label: 'Vrstna',
    },
    {
      value: 'dvojček',
      label: 'Dvojček',
    },
    {
      value: 'trojček',
      label: 'Trojček',
    },
    {
      value: 'dvostanovanjska',
      label: 'Dvostanovanjska',
    },
    {
      value: 'večstanovanjska',
      label: 'Večstanovanjska',
    },
    {
      value: 'poslovno-stanovanjska',
      label: 'Poslovno-stanovanjska',
    }],
    landRealEstateSubtype: [ {
      value: 'kmetijska_povrsina',
      label: 'Kmetijska površina',
    },
    {
      value: 'gozd',
      label: 'Gozd',
    },
    {
      value: 'zazidljiva',
      label: 'Zazidljiva',
    },
    {
      value: 'nezazidljiva',
      label: 'Nezazidljiva',
    },
    {
      value: 'za_investicijo',
      label: 'Za investicijo',
    }],
    businessRealEstateSubtype: [ {
      value: 'pisarna',
      label: 'Pisarna',
    },
    {
      value: 'prostor_za_storitve',
      label: 'Prostor za storitve',
    },
    {
      value: 'delavnica',
      label: 'Delavnica',
    },
    {
      value: 'gostinski_lokal',
      label: 'Gostinski lokal',
    },
    {
      value: 'skladisce',
      label: 'Skladišče',
    }],
    vacationRealEstateSubtype: [ {
      value: 'apartma',
      label: 'Apartma',
    },
    {
      value: 'vikend',
      label: 'Vikend',
    },
    {
      value: 'hiša',
      label: 'Hiša',
    }]
  }