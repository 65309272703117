import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";

import logo from "../assets/sansim_logo.png";
import {renderTitle} from "../utils/utils.js";



const pages = ["Nepremičnine", "Kontakt", "O_nas", "Domov"];
const drawerWidth = 240;

function MainNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menu
      </Typography>
      <Divider />
      <List>
        {pages.map((page) => (
          <ListItem key={page} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link
                to={`/${page === "Domov" ? "" : page}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontStyle: "Josefin Sans",
                  fontWeight: "bold",
                }}
              >
                {renderTitle(page)}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "white",}} >
        <Toolbar sx={{ background: "white", justifyContent: "space-between" }}>
          <IconButton
            color="disabled"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon color="disabled" />
          </IconButton>
          <Box
            onClick={() => {navigate("/"); navigate(0)}} 
            component="img"
            sx={{
              height: 64,
            }}
            alt="Your logo."
            src={logo}
          />
          <Divider />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {pages.map((page) => (
              <Button key={page} sx={{ color: "#000" }}>
                <Link
                  to={`/${page === "Domov" ? "" : page}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bold",
                    marginRight: "30px"
                  }}
                >
                  {renderTitle(page)}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Container component="main" maxWidth={false} className="p-0">
        <Toolbar />
        <Outlet />
      </Container>
    </Box>
  );
}

export default MainNavbar;
