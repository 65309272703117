import React from "react";

import { Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import backgroundImage from "../assets/hisa_1.png";
import { Image } from "mui-image";
import Footer from "../components/Footer";

import EmailForm from "../components/EmailForm";

function ContactPage() {
  const isMobile =  window.innerWidth < 800;
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: isMobile ? 30 : 35,
        fontStyle: "Open Sans",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: isMobile ? 30 : 32,
        fontStyle: "Open Sans",
      },
      boxtext: {
        fontWeight: 400,
        fontSize: isMobile ? 18 : 20,
        fontStyle: "Open Sans",
      },
    },
  }); 

  var margin = "16px";
  return (
    <Box>
      <Box paddingBottom={"100px"}>
        <Image src={backgroundImage} style={{ opacity: 0.7 }} />
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
        <Stack gap={4} marginY={"0px"} maxWidth={"600px"} alignItems={"center"}>
          <ThemeProvider theme={theme}>
            <Typography variant="title" marginX={margin} align="center">Sansim nepremičnine d.o.o.</Typography>
            <Stack
              maxWidth={"600px"}
              minHeight={"200px"}
              justifyContent={"space-between"}
              marginX={margin}
            >
              <Stack direction={"row"} gap={8}>
                <Typography variant="boxtext">Naslov:</Typography>
                <Typography variant="boxtext">
                  Ulica Slavka Gruma 124, 8000 Novo mesto
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={8}>
                <Typography variant="boxtext">Telefon:</Typography>
                <Typography variant="boxtext">
                  041 310 880 <br /> 051 680 301
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={8}>
                <Typography variant="boxtext">E-mail:</Typography>
                <Typography variant="boxtext">info@sansim.si</Typography>
              </Stack>
            </Stack>
            <Typography variant="boxtext" marginX={margin} align="center">
              Ogledi nepremičnin potekajo po dogovoru.{" "}
            </Typography>
          </ThemeProvider>
          <EmailForm />
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
}

export default ContactPage;
