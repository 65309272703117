import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Footer from "../components/Footer";

import { capitalize, renderYear } from "../utils/utils.js";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';


import { ref, listAll, getBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { TextDecoder } from "text-encoding";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});


function RealEstateDetailPage() {
  const { id } = useParams();

  const [displayedRealEstate, setDisplayedRealEstate] = useState();

  const [loading, setLoading] = useState(false);
  const isMobile =  window.innerWidth < 800;

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const listRef = ref(storage, "");
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getBytes(itemRef)
            .then((bytes) => {
              let json = new TextDecoder().decode(bytes);
              json = JSON.parse(json);
              // Here you can get the download URL for 'images/stars.jpg'
              json.id = itemRef.name;

              if (json.id.slice(2, -5) === id) {
                setDisplayedRealEstate(json);
              }
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log("error: ", error);
            });
        });
      })
      .catch((error) => {
        console.log("error: ", error);
        // Uh-oh, an error occurred!
      });
  }, []);
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 500,
        fontSize: 30,
        fontStyle: "Josefin Sans",
        color: "#283B62",
      },
      price: {
        fontWeight: 500,
        fontSize: isMobile ? 22 : 28,
        fontStyle: "Josefin Sans",
        color: "#283B62",
      },
      description: {
        fontWeight: 100,
        fontSize: isMobile ? 18 : 22,
        fontStyle: "Josefin Sans",
        color: "#283B62",
      },
      titleBox: {
        fontWeight: 500,
        fontSize: 30,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
      descriptionBox: {
        fontWeight: 500,
        fontSize: 18,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
    },
  });

  function renderText(text) {
    var newText = [];

    text.forEach((item, i) => {
      newText.push(item);
      newText.push(<br />);
    });

    return newText;
  }
  return (
    <Box>
      <ThemeProvider theme={theme}>

        {displayedRealEstate && (
          <Carousel
          swipeable={true}
          showArrows={true}
          onChange={() => {}}
          onClickItem={() => {}}
          onClickThumb={() => {}}
        >
          <Box
            sx={{
              height: isMobile ? "60vh" : "80vh",
              aspectRatio: "auto",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={displayedRealEstate.url || displayedRealEstate.mainImage}
              alt="Real Estate"
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
            />
          </Box>
          {displayedRealEstate.images.map((image) => (
            <Box
              key={image.url || image.mainImage}
              sx={{
                height: isMobile ? "60vh" : "80vh",
                aspectRatio: "auto",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={image.url || image.mainImage}
                alt="Real Estate"
                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
              />
            </Box>
          ))}
        </Carousel>
        )}
        {displayedRealEstate && (
          <div>
            <Box paddingX={"5%"} paddingY={"20px"}>
              <Stack>
                <Typography variant="title">
                  {displayedRealEstate.title}
                </Typography>
                <Grid container direction="row"
                alignItems="center">
                  <Grid item xs={isMobile ? 12 : 4} display={"flex"} justifyContent={"start"}>
                    <Typography variant="description">
                      {capitalize(displayedRealEstate.adType)}
                    </Typography>
                    <Typography variant="description" marginX={isMobile ? "12px": "30px"}>
                      |
                    </Typography>
                    <Typography variant="description">
                      {capitalize(displayedRealEstate.type)}
                    </Typography>
                    <Typography variant="description" marginX={isMobile ? "12px": "30px"}>
                      |
                    </Typography>
                    <Typography variant="description">
                      {capitalize(displayedRealEstate.subtype)}
                    </Typography>
                  </Grid>
                  <Grid
                    item 
                    xs={isMobile ? 3 : 1}
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems="center"
                  >
                    <CalendarMonthIcon
                      sx={{ color: "#283B62", marginRight: "10px" }}
                      fontSize="small"
                    />
                    <Typography variant="description">
                      {renderYear(displayedRealEstate.date)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 5 : 2}
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems="center"
                  >
                    <HomeIcon
                      sx={{ color: "#283B62", marginRight: "10px" }}
                      fontSize="small"
                    />
                    <Typography variant="description">
                      {displayedRealEstate.area} m<sup>2</sup>
                    </Typography>
                  </Grid>
                  <Grid item xs={isMobile ? 4 : 5}>
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"end"}
                    >
                      <Typography variant="price">
                        {displayedRealEstate.adType === "prodaja"
                          ? `${displayedRealEstate.price} €`
                          : `${displayedRealEstate.price} €/Mesec`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            <Box
              sx={{
                backgroundColor: "#283B62",
                paddingX: "5%",
                paddingY: "50px",
              }}
            >
              <Stack gap={3}>
                <Typography variant="titleBox">
                  Opis nepremičnine
                </Typography>
                <Typography variant="descriptionBox">
                  {renderText(displayedRealEstate.description.split("\n")).map(
                    (e, i) => (
                      <div key={i}>{e}</div>
                    )
                  )}
                </Typography>
              </Stack>
            </Box>
          
        <Box height={isMobile ? "400px" : "500px"} display={"flex"} justifyContent={"center"}>
          <MapContainer
            center={[Number(displayedRealEstate.lat), Number(displayedRealEstate.lng)]}
            zoom={13}
            style={{ height: isMobile ? "400px" : "500px", width: "100%" }}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[Number(displayedRealEstate.lat), Number(displayedRealEstate.lng)]} icon={DefaultIcon}>
              <Popup>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do...
              </Popup>
            </Marker>
          </MapContainer>
        </Box>
        </div>
        )}
        <Footer />
      </ThemeProvider>
    </Box>
  );
}

export default RealEstateDetailPage;
