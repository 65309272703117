import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const loggedIn = sessionStorage.getItem('user') ? true : false; 

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return loggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoute;