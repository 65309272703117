import React from "react";

import { Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import backgroundImage from "../assets/hisa_1.png";
import { Image } from "mui-image";
import Footer from "../components/Footer";

import AboutItem from "../components/AboutItem";

function AboutPage() {
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 35,
        fontStyle: "Open Sans",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: 32,
        fontStyle: "Open Sans",
      },
      boxtext: {
        fontWeight: 400,
        fontSize: 20,
        fontStyle: "Open Sans",
      },
    },
  });
  return (
    <Box>
      <Box paddingBottom={"100px"}>
        <Image src={backgroundImage} style={{ opacity: 0.7 }} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingX={"20px"}
      >
        <Stack
          gap={8}
          marginBottom={"80px"}
          maxWidth={"600px"}
          alignItems={"center"}
        >
          <ThemeProvider theme={theme}>
            <Typography variant="title">Skupaj do sanjskega doma</Typography>
            <Stack gap={6} marginY={"20px"}>
              <AboutItem
                title={"Naša zgodba"}
                text={
                  "Že od leta 1234 delujemo na trgu in tralala mal zgodovine o firmi pa tko."
                }
              />
              <AboutItem
                title={"Naše poslanstvo"}
                text={
                  "Naše poslanstvo je da vam najdemo pravo nepremičnino oz. da vam pomagamo pri prodaji le te."
                }
              />
              <AboutItem
                title={"Naša vizija"}
                text={
                  "Tej trije okvički so v bistvu lahko karkoli, jaz sem jim dal čisto random poimenovanje. bol se gre za to, da malo razbijemo in zapolnimo stran, da zgleda bolj zanimiva."
                }
              />
            </Stack>
          </ThemeProvider>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
}

export default AboutPage;
