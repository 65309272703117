import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ref, listAll, deleteObject , getBytes} from "firebase/storage";
import { storage } from "../firebase/firebase";
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { TextDecoder } from "text-encoding";

export default function DataTable() {
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // Create a reference under which you want to list

    const columns = [
      { field: 'title', headerName: 'naslov', width: 130 },
        { field: 'description', headerName: 'opis', width: 130 },
        { field: 'price', headerName: 'cena', width: 130 },
        { field: 'area', headerName: 'povrsina', width: 130 },
        { field: 'date', headerName: 'datum izdelave', width: 130 },
        { field: 'type', headerName: 'tip', width: 130 },
        { field: 'subtype', headerName: 'podtip', width: 130 },
        { field: 'adType', headerName: 'vrsta', width: 130 },
        {
          field: "uredi",
          headerName: "uredi",
          sortable: false,
          renderCell: (params) => {
            const onClick = (e) => {
              // save params.row to local storage and redirect to edit page
              localStorage.setItem('realEstate', JSON.stringify(params.row));
              e.stopPropagation(); // don't select this row after clicking
              window.location.href = "/edit";
            };
      
            return <Button onClick={onClick}>Uredi</Button>;
          }
        },
        {
          field: "izbrisi",
          headerName: "izbrisi",
          sortable: false,
          renderCell: (params) => {
            const onClick = (e) => {
              // save params.row to local storage and redirect to edit page
              const storageRef = ref(storage,  params.row.id);
              deleteObject(storageRef).then(() => {
                // File deleted successfully
                // remove from rows
                setRows(oldRows=>oldRows.filter((row)=>row.id!==params.row.id))
              }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log("error")
              });
              e.stopPropagation(); // don't select this row after clicking
            };
      
            return <Button onClick={onClick}>Izbriši</Button>;
          }
        }
    
    ];
    
    // Find all the prefixes and items.
    useEffect(()=>{
        if (loading) {
            return
        }
        setLoading(true)
        const listRef = ref(storage, '');
        listAll(listRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              // All the items under listRef.
              // read bytes to json and add to rows
             getBytes(itemRef).then((bytes) => {
              let json = new TextDecoder().decode(bytes);
                json = JSON.parse(json)
                // Here you can get the download URL for 'images/stars.jpg'
                json.id = itemRef.name
                setRows(oldRows=>[...oldRows, json])
              }
                ).catch((error) => {
                // Uh-oh, an error occurred!
                console.log('error: ', error);
                });
            });
          }).catch((error) => {
            console.log('error: ', error);
            // Uh-oh, an error occurred!
          });
        // do stuff here...
    }, [])


  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        
      />
      <Button variant="contained" color="primary" href="/new">
        Dodaj nepremičnino
      </Button>
    </div>
  );
}