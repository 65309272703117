import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { storage} from '../firebase/firebase';
import { ref, uploadBytes } from "firebase/storage";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { enums } from '../utils/enums';


export const AddRealEstate = () => {
    const navigate = useNavigate();
    const [previewImage, setPreviewImage] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/*',
      onDrop: acceptedFiles => {
        setPreviewImage(
          acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
       );
      }
    });
    const [values, setValues] = useState({
    mainImage: [],
    title: '',
    description: '',
    type: 'hisa',
    subtype: '',
    price: 0,
    area: 0,
    adType: '',
    date: '',
    lat: 46.0569,
    lng: 14.5058,
    images: [],
  });

  const [open, setOpen] = useState(false);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const renderMenuItems = (items) => {
    return items.map((item, index) => {
      return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
    })
  }

  const renderSubtypeMenuItems = () => {
    const type = values.type;
    
    switch(type) {
      case 'stanovanje':
        return renderMenuItems(enums.flatRealEstateSubtype);
      case 'hisa':
        return renderMenuItems(enums.houseRealEstateSubtype);
      case 'parcela':
        return renderMenuItems(enums.landRealEstateSubtype);
      case 'poslovni_prostor':
        return renderMenuItems(enums.businessRealEstateSubtype);
      case 'pocitniski_objekt':
        return renderMenuItems(enums.vacationRealEstateSubtype);
      default:
        return renderMenuItems(enums.houseRealEstateSubtype);;
    }
  }


  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]: event.target.value
    });
    };

    const addNewImage = () => {
      setValues({
        ...values,
        images: [...values.images, {url: '', description: ''}]
      });
    }

    const handleImagesChange = async (event) => {
      const name = event.target.name;
      const value = name.includes('url') ? await convertBase64(event.target.files[0]) : event.target.value;
      const index = name.split('.')[1];
      const images = [...values.images];
      images[index][name.split('.')[2]] = value;
      setValues({
          ...values,
          images: images
      });
      };

      const handleFile = async (e) => {
        setValues({
          ...values,
          mainImage: await convertBase64(e.target.files[0])
       });
      };

  const handleSubmit = async (event) => {
    setOpen(true);
    const jsonToSend = JSON.stringify(values);
    const blob = new Blob([jsonToSend], { type: 'application/json' });
    const storageRef = ref(storage, Math.random() + '.json');
    uploadBytes(storageRef, blob).then((snapshot) => {
      setOpen(false);
        navigate('/list');
    });
    event.preventDefault();

    // redirect to /list
  };
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    previewImage.forEach(file => URL.revokeObjectURL(file.preview));
  }, [previewImage]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <section className="container">
                <div className="drop-container">
                <div className="drop-message">
                  <div {...getRootProps({className: 'upload-icon'})}>
                    <input {...getInputProps({
                    onChange: handleFile,
                  })} />
                  <p>Klikni in povleci slike sem, ali klikn za izbiro slik</p>
                </div>
                </div>
               </div>
                <aside >
                </aside>
              </section>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <div >
                <div >
                  <img
                    src={values.mainImage}
                    style={{height: 200, width: 'auto', margin: 'auto' }}
                  />
                </div>
              </div>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Naslov"
                  name="title"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={handleChange}
                  required
                  value={values.title}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Površina"
                  name="area"
                  onChange={handleChange}
                  required
                  type="number"
                  inputProps={{
                    min: 0,
                    step: "1.0"
                  }}
                  value={values.area}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <TextField
                  fullWidth
                  label="Longitude"
                  name="lng"
                  onChange={handleChange}
                  required
                  type="number"
                  inputProps={{
                    step: "0.0000001"
                  }}
                  value={values.lng}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
              >
                <TextField
                  fullWidth
                  label="Latitude"
                  name="lat"
                  onChange={handleChange}
                  required
                  type="number"
                  inputProps={{
                    step: "0.0000001"
                  }}
                  value={values.lat}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  name="date"
                  type="date"
                  helperText="Datum izgradnje"
                  onChange={handleChange}
                  required
                  value={values.date}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Cena (eur)"
                  name="price"
                  type="number"
                  onChange={handleChange}
                  required
                  value={values.price}
                />
              </Grid>
              <Grid
                xs={12}
                md={12}
              >

              <Grid
                xs={12}
                md={6}
              >
                <FormControl fullWidth>
                  <InputLabel id="adType">
                    Tip oglasa
                  </InputLabel>
                  <Select
                    labelId="adType"
                    id="adType"
                    value={values.adType}
                    name="adType"
                    label="adType"
                    onChange={handleChange}
                  >
                    {
                      renderMenuItems(enums.adType)
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <FormControl fullWidth>
                  <InputLabel id="type">Tip nepremičnine</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    value={values.type}
                    name="type"
                    label="Type"
                    onChange={handleChange}
                  >
                    {
                      renderMenuItems(enums.realEstateType)
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <FormControl fullWidth>
                  <InputLabel id="subtype">Podtip nepremičnine</InputLabel>
                  <Select
                    labelId="subtype"
                    id="subtype"
                    value={values.subtype}
                    name="subtype"
                    label="Podtip"
                    onChange={handleChange}
                  >
                    {
                      renderSubtypeMenuItems()
                    }
                  </Select>
                </FormControl>
              </Grid>
                <TextField
                  fullWidth
                  label="Opis nepremičnine"
                  name="description"
                  onChange={handleChange}
                  required
                  multiline
                  rows={4}
                  value={values.description}
                />
              </Grid>

             {values.images.map((image, i) => {
                return (
                  <><Grid
                    xs={12}
                    md={12}
                  >
                      <section className="container">
                        <div className="drop-container">
                        <div className="drop-message">
                          <div {...getRootProps({className: 'upload-icon'})}>
                            <input 
                            name={ 'images.' + i + ".url" }
                            {...getInputProps({
                            onChange: handleImagesChange,
                          })} />
                          <p>Klikni in povleci slike sem, ali klikn za izbiro slik</p>
                        </div>
                        </div>
                      </div>
                      </section>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                  >
                      <TextField
                        fullWidth
                        label="Description"
                        name={ 'images.' + i + ".description" }
                        onChange={handleImagesChange}
                        required
                        multiline
                        rows={4}
                        value={values.images[i].description} />
                    </Grid>
                    <Grid
                    xs={12}
                    md={6}
                  >
                      <img src={values.images[i].url} alt="preview"
                      height="150px" object-fit="contain"/>
                    </Grid>
                    
                    </>
                )
              }
              )}
              <Grid
                xs={12}
                md={6}
              >
                <Button
                  variant="contained"
                  component="label"
                  onClick={addNewImage}
                >
                  Add Image
                  </Button>
              </Grid>

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" value="Submit">
            Shrani podatke
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default AddRealEstate;
