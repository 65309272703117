import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import backgroundImage from "../assets/hisa_1.png";
import bottomImage from "../assets/svetovanje_1.jpg";
import { Image } from "mui-image";
import Footer from "../components/Footer";
import RealEstateCard from "../components/RealEstateCard";
import SearchBar from "../components/SearchBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { ref, listAll, getBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { TextDecoder } from "text-encoding";

import { capitalize } from "../utils/utils";

function HomePage() {
  const screenWidth = window.innerWidth;
  const isMobile =  window.innerWidth < 800;
  var cardCount;
  if (screenWidth < 800) {
    cardCount = 1;
  } else if (screenWidth < 1200) {
    cardCount = 2;
  } else {
    cardCount = 3;
  }

  const [displayedRealEstate, setDisplayedRealEstate] = useState([]);
  const [displayedIndexes, setDisplayedIndexes] = useState({
    lower: 0,
    upper: cardCount,
  });
  const [loading, setLoading] = useState(false);
  const [businessType, setBusinessType] = useState(new Set());
  const [types, setTypes] = useState(new Set());
  const [subtypes, setSubTypes] = useState(new Set());

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const listRef = ref(storage, "");
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          // read bytes to json and add to rows
          getBytes(itemRef)
            .then((bytes) => {
              let json = new TextDecoder().decode(bytes);
              json = JSON.parse(json);
              // Here you can get the download URL for 'images/stars.jpg'
              json.id = itemRef.name;
              setDisplayedRealEstate((oldRows) => [...oldRows, json]);
              setBusinessType(
                (oldBusinessType) =>
                  new Set([...oldBusinessType, capitalize(json.adType)])
              );
              setTypes(
                (oldTypes) => new Set([...oldTypes, capitalize(json.type)])
              );
              setSubTypes(
                (oldSubtypes) =>
                  new Set([...oldSubtypes, capitalize(json.subtype)])
              );
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log("error 1: ", error);
            });
        });
      })
      .catch((error) => {
        console.log("error 2: ", error);
        // Uh-oh, an error occurred!
      });
  }, []);

  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 35,
        fontStyle: "Open Sans",
      },
      boxTitle: {
        fontWeight: 400,
        fontSize: isMobile ? 20 :  32,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
      boxtext: {
        fontWeight: 200,
        fontSize: isMobile ? 14 : 20,
        fontStyle: "Open Sans",
        color: "#FFFFFF",
      },
    },
  });

  function handlePaginationChange(e, page) {
    if (page * cardCount >= displayedRealEstate.length) {
      setDisplayedIndexes({
        lower: cardCount * (page - 1),
        upper: displayedRealEstate.length,
      });
    } else {
      setDisplayedIndexes({
        lower: cardCount * (page - 1),
        upper: cardCount * (page - 1) + cardCount,
      });
    }
  }

  function onSelect(selected) {
    if (businessType.has(selected)) {
      setTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.adType === selected.toLowerCase())
            .map((e) => capitalize(e.type))
        )
      );

      setSubTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.adType === selected.toLowerCase())
            .map((e) => capitalize(e.subtype))
        )
      );
    } else if (types.has(selected)) {
      setSubTypes(
        new Set(
          displayedRealEstate
            .filter((e) => e.type === selected.toLowerCase())
            .map((e) => capitalize(e.subtype))
        )
      );
    }
  }

  return (
    <Box>
      <Box paddingBottom={"60px"}>
        <Image src={backgroundImage} />
      </Box>
      <ThemeProvider theme={theme}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginY={"20px"}
        >
          <Typography variant="title">Iskanje nepremičnin</Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} marginY={"60px"}>
          <SearchBar
            businessType={businessType}
            types={types}
            subtypes={subtypes}
            onSelect={onSelect}
          />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          justifyContent={"center"}
          marginY={"60px"}
        >
          {displayedRealEstate
            .slice(displayedIndexes.lower, displayedIndexes.upper)
            .map((realEstate) => (
              <RealEstateCard
                key={realEstate.id}
                image={realEstate.url ? realEstate.url : realEstate.mainImage}
                address={realEstate.title}
                price={realEstate.price}
                type={realEstate.type}
                build_year={realEstate.date}
                area={realEstate.area}
                subType={realEstate.subtype}
                adType={realEstate.adType}
                id={realEstate.id.slice(2, -5)}
              />
            ))}
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          marginY={"30px"}
        >
          <Pagination
            count={Math.ceil(displayedRealEstate.length / cardCount)}
            onChange={(e, page) => handlePaginationChange(e, page)}
          />
        </Stack>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginY={"80px"}
        >
          <Typography variant="title" align="center" marginX={"18px"}>
            Želite prodati ali oddati nepremičnino?
          </Typography>
        </Stack>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Card
            sx={{
              width: "650px",
              height: "400px",
              borderRadius: 0,
              border: "none",
              boxShadow: "none",
              marginBottom: "80px",
            }}
          >
            <CardActionArea component={Link} to={`/Kontakt`}>
              <CardContent sx={{ padding: "0px" }}>
                <Grid direction={"row"} container maxHeight={"400px"}>
                  <Grid
                    item
                    xs={6}
                    bgcolor={"#1E3054"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    padding={"20px"}
                  >
                    <Stack>
                      <Typography textAlign={"center"} variant="boxTitle">
                        Kontaktirajte nas
                      </Typography>
                      <Box height={isMobile ? "20px" : "40px"}></Box>
                      <Typography textAlign={"center"} variant="boxtext">
                        Želite prodati ali oddati hišo, stanovanje oziroma
                        zemljišče? Z veseljem vam pomagamo in vam v najkrajšem
                        mođnem času poiščemo kupca ali najemnnika.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} paddingY={"35px"}>
                    <Image src={bottomImage} />
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </ThemeProvider>
      <Footer />
    </Box>
  );
}

export default HomePage;
