import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import CardActionArea from "@mui/material/CardActionArea";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";

import {capitalize, renderYear} from "../utils/utils.js";

import { Link } from "react-router-dom";

function RealEstateCard({
  image,
  address,
  type,
  build_year,
  area,
  price,
  subType,
  adType,
  id,
}) {
  const theme = createTheme({
    typography: {
      title: {
        fontWeight: 400,
        fontSize: 18,
        fontStyle: "Josefin Sans",
        color: "#283B62",
      },
      description: {
        fontWeight: 200,
        fontSize: 16,
        fontStyle: "Josefin Sans",
        color: "#283B62",
      },
    },
  });
  return (
    <Card sx={{ width: 350, borderRadius: 5 }}>
      <CardActionArea component={Link} to={`/Nepremičnine/${id}`}>
        <CardMedia sx={{ height: 318 }} image={image} title="green iguana" />
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography variant="title" component="div">
              {address}
            </Typography>
            <Typography variant="description" component="div">
              {`${capitalize(adType)},  ${capitalize(type)},  ${capitalize(subType)}`}
            </Typography>
            <Stack direction="row" gap={2} alignItems="end" >
              <Typography variant="title">{type === "prodaja" ? `${price} €` : `${price} €/Mesec`}</Typography>
              <Stack direction="row" alignItems="start" >
                <CalendarMonthIcon sx={{ color: "#283B62" }} fontSize="small" />
                <Typography variant="description" alignItems={"center"}>
                  {renderYear(build_year)}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="start">
                <HomeIcon sx={{ color: "#283B62" }} fontSize="small" />
                <Typography variant="description" alignItems={"center"}>
                  {area} m<sup>2</sup>
                </Typography>
              </Stack>
            </Stack>
          </ThemeProvider>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default RealEstateCard;
